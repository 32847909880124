import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const useFourIconsInRowAnimations = (
  timeline: any,
  subtitleRef: MutableRefObject<HTMLHeadingElement>,
  pageTitleRef: MutableRefObject<HTMLHeadingElement>
) => {
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline.current, subtitleRef.current)
    createShowFromBottomAnimation(
      timeline.current,
      pageTitleRef.current,
      subtitleRef.current
    )
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export const useSingleIconAnimation = (
  timeline: any,
  singleIconRef: MutableRefObject<HTMLHeadingElement>,
  iconContent: MutableRefObject<HTMLHeadingElement>
) => {
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline.current, singleIconRef.current)
    createShowFromBottomAnimation(timeline.current, iconContent.current)
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
