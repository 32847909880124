import React, { FC, useRef } from 'react'
import Button from 'src/components/ui/Button'
import { singleAnnouncementAnimation } from 'src/components/sections/announcements/animations'

export interface ISingleAnnouncementData {
  status: string
  location: string
  position: string
  requirements: string
  mailSubject: string
}

interface ISingleAnnouncementProps extends ISingleAnnouncementData {
  action: string
  timeline: any
}

const SingleAnnouncement: FC<ISingleAnnouncementProps> = ({
  status,
  location,
  position,
  requirements,
  mailSubject,
  action,
  timeline,
}) => {
  const singleAnnouncementRef = useRef<HTMLDivElement>(null)

  singleAnnouncementAnimation(timeline, singleAnnouncementRef)

  return (
    <div
      ref={singleAnnouncementRef}
      className="opacity-0 w-full p-5 border-2 border-smallLightGray rounded-xl"
    >
      <div className="flex justify-between mb-4">
        <span className="text-sm sm:text-base text-red font-palanquinDark font-bold tracking-extreme">
          {status}
        </span>
        <span className="text-sm sm:text-lg text-mediumDarkGray font-semibold text-right ml-6">
          {location}
        </span>
      </div>
      <div className="sm:flex sm:justify-between sm:items-end">
        <div className="mb-5 sm:mb-0">
          <h3 className="font-palanquinDark text-base sm:text-2xl text-secondaryBlack font-semibold mb-3 sm:mb-1.5 w-44 sm:w-full">
            {position}
          </h3>
          <p className="text-xs sm:text-base font-medium sm:font-normal text-mediumDarkGray">
            {requirements}
          </p>
        </div>
        <a href={`mailto:studio@mamf.pl?subject=${mailSubject}`}>
          <Button className="min-w-full sm:min-w-0 sm:ml-10">{action}</Button>
        </a>
      </div>
    </div>
  )
}
export default SingleAnnouncement
