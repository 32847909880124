// i18next-extract-mark-ns-start career

import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPerk } from 'src/components/sections/benefits-and-perks'

export const useListOfPerks = (): IPerk[] => {
  const { t } = useTranslation('career')

  return [
    {
      title: t('1perk-title'),
      img: t('1perk-img'),
      description: t('1perk-description'),
    },
    {
      title: t('2perk-title'),
      img: t('2perk-img'),
      description: t('2perk-description'),
    },
    {
      title: t('3perk-title'),
      img: t('3perk-img'),
      description: t('3perk-description'),
    },
    {
      title: t('4perk-title'),
      img: t('4perk-img'),
      description: t('4perk-description'),
    },
    {
      title: t('5perk-title'),
      img: t('5perk-img'),
      description: t('5perk-description'),
    },
    {
      title: t('6perk-title'),
      img: t('6perk-img'),
      description: t('6perk-description'),
    },
    {
      title: t('7perk-title'),
      img: t('7perk-img'),
      description: t('7perk-description'),
    },
    {
      title: t('8perk-title'),
      img: t('8perk-img'),
      description: t('8perk-description'),
    },
  ]
}

export default {}
