import * as React from 'react'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import Hero from 'src/components/sections/hero'
import Announcements from 'src/components/sections/announcements/Announcements'
import FourIconsInRow from 'src/components/sections/four-icons-in-row'
import BenefitsAndPerks from 'src/components/sections/benefits-and-perks'
import { useRef } from 'react'
import ContactSection from 'src/components/sections/ContactSection'
import { directusFileUrl } from 'src/helpers/directus'

const IndexPage = ({ data }) => {
  const translation = data.directus.pages[0].translations[0]
  if (!translation) {
    return <h1>Missing translation</h1>
  }
  const cmsJson = JSON.parse(translation.content)
  const ourTeamSection = {
    ...cmsJson.ourTeamSection,
    icons: cmsJson.ourTeamSection.icons.map((icon, index) => ({
      id: `${index}`,
      ...icon,
    })),
  }
  const announcementsRef = useRef(null)
  const scrollToAnnouncements = () => announcementsRef.current?.scrollIntoView()

  return (
    <Layout title={cmsJson.title} metas={cmsJson.metas}>
      <Hero
        translationContent={cmsJson.firstSection}
        onButtonClick={scrollToAnnouncements}
        showBadges
      />
      <Announcements
        ref={announcementsRef}
        translationContent={cmsJson.jobOffersSection}
        announcements={data.directus.job_offers.map(({ id, translations }) => ({
          id,
          ...translations[0],
        }))}
      />
      <div className="relative">
        <Hero
          isH2
          translationContent={cmsJson.getInTouchSection}
          isFullHeight={false}
        />
        <img
          className="w-72 lg:w-4/12 mx-auto lg:absolute lg:right-12 bottom-0 -z-10 mb-10 md:mb-0"
          src={directusFileUrl('14b0a09c-9da5-4274-b1da-21848d875b38.png')}
          alt="Contact"
        />
      </div>
      <FourIconsInRow translationContent={ourTeamSection} />
      <BenefitsAndPerks />
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      pages(filter: { slug: { _eq: "/career" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          content
        }
      }
      job_offers(sort: "sort") {
        id
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          status
          location
          position
          requirements
          mailSubject: mail_subject
        }
      }
    }
  }
`

export default IndexPage
