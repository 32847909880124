import React, { forwardRef, useRef } from 'react'
import SingleAnnouncement, {
  ISingleAnnouncementData,
} from 'src/components/sections/announcements/SingleAnnouncement'
import Subtitle from 'src/components/ui/Subtitle'
import { announcementsAnimations } from 'src/components/sections/announcements/animations'
import { gsap } from 'gsap/all'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface IAnnouncementsProps {
  translationContent: {
    sectionName: string
    subtitle: string
    action: string
  }
  announcements: ISingleAnnouncementData[]
}

const VerticalGapBox = styled.div`
  & > *:not(:last-child) {
    ${tw`mb-10`}
  }
`

const Announcements = forwardRef<HTMLDivElement, IAnnouncementsProps>(
  (
    { translationContent: { subtitle, action, sectionName }, announcements },
    ref
  ) => {
    const timeline = useRef(gsap.timeline())
    const subtitleRef = useRef<HTMLDivElement>(null)

    announcementsAnimations(timeline.current, subtitleRef)

    return (
      <section
        ref={ref}
        data-name={sectionName}
        id={sectionName}
        className="container py-10 md:py-20"
      >
        <div className="w-full max-w-3xl mx-auto">
          <Subtitle
            ref={subtitleRef}
            className="opacity-0 mb-4 md:mb-6 font-palanquinDark"
          >
            {subtitle}
          </Subtitle>
          <VerticalGapBox>
            {announcements.map(
              ({ status, location, position, requirements, mailSubject }) => (
                <SingleAnnouncement
                  timeline={timeline.current}
                  action={action}
                  status={status}
                  location={location}
                  position={position}
                  requirements={requirements}
                  mailSubject={mailSubject}
                />
              )
            )}
          </VerticalGapBox>
        </div>
      </section>
    )
  }
)

export default Announcements
