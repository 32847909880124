import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const useBenefitsAndPerksAnimations = (
  selectedPerkRef: MutableRefObject<HTMLDivElement>,
  subtitleRef: MutableRefObject<HTMLDivElement>,
  titleRef: MutableRefObject<HTMLDivElement>,
  descriptionRef: MutableRefObject<HTMLDivElement>,
  listOfPerksRef: MutableRefObject<HTMLDivElement>
) => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline.current, selectedPerkRef.current)
    createShowFromBottomAnimation(timeline.current, subtitleRef.current)
    createShowFromBottomAnimation(timeline.current, titleRef.current)
    createShowFromBottomAnimation(timeline.current, descriptionRef.current)
    createShowFromBottomAnimation(timeline.current, listOfPerksRef.current)
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
