// i18next-extract-mark-ns-start career

import React, { FC, useState, useRef } from 'react'
import H2 from 'src/components/ui/title/H2'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Subtitle from 'src/components/ui/Subtitle'
import SelectedPerk from 'src/components/sections/benefits-and-perks/SelectedPerk'
import ListOfPerks from 'src/components/sections/benefits-and-perks/ListOfPerks'
import { useListOfPerks } from 'src/components/sections/benefits-and-perks/list-of-perks'
import { useBenefitsAndPerksAnimations } from 'src/components/sections/benefits-and-perks/animations'

interface IBenefitsAndPerksProps {
  className?: string
}

export interface IPerk {
  title: string
  img: string
  description: string
}

const BenefitsAndPerks: FC<IBenefitsAndPerksProps> = ({ className }) => {
  const perks = useListOfPerks()
  const { t } = useTranslation('career')
  const [selectedPerk, setSelectedPerk] = useState<IPerk>(perks[0])

  const selectedPerkRef = useRef<HTMLDivElement>(null)
  const subtitleRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const listOfPerksRef = useRef<HTMLDivElement>(null)

  useBenefitsAndPerksAnimations(
    selectedPerkRef,
    subtitleRef,
    titleRef,
    descriptionRef,
    listOfPerksRef
  )

  return (
    <section
      data-name={t('our-perks')}
      id={t('our-perks')}
      className={`${className} py-10 md:py-20`}
    >
      <div className="container flex flex-col-reverse md:flex-row md:justify-between flex-wrap items-start">
        <div ref={selectedPerkRef} className="opacity-0 w-full md:w-4/12 mt-12">
          <SelectedPerk perk={selectedPerk} />
        </div>
        <div className="w-full md:w-8/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 mt-12 md:mt-0 md:pl-12 md:text-right md:flex md:flex-wrap">
          <Subtitle ref={subtitleRef} className="opacity-0 w-full">
            {t('our perks')}
          </Subtitle>
          <H2
            ref={titleRef}
            className="opacity-0 w-full"
            firstPart={t('benefits')}
            secondPart={t('& perks')}
          />
          <p ref={descriptionRef} className="opacity-0 mt-12">
            {t('perks-and-benefits-description')}
          </p>
        </div>
      </div>
      <div ref={listOfPerksRef} className="opacity-0 container">
        <ListOfPerks
          selectedPerk={selectedPerk}
          setSelectedPerk={setSelectedPerk}
          perks={perks}
        />
      </div>
    </section>
  )
}
BenefitsAndPerks.defaultProps = {
  className: '',
}

export default BenefitsAndPerks
