import React, { FC } from 'react'
import { IPerk } from 'src/components/sections/benefits-and-perks'
import { directusDownloadUrl } from 'src/helpers/directus'

interface IListOfPerksProps {
  className?: string
  perks: Array<IPerk>
  selectedPerk: IPerk
  setSelectedPerk: (perk: IPerk) => void
}

const ListOfPerks: FC<IListOfPerksProps> = ({
  className,
  perks,
  setSelectedPerk,
  selectedPerk,
}) => (
  <div
    className={`${className} lg:flex justify-between mt-16 grid grid-cols-4`}
  >
    {perks.map((perk) => {
      const isSelected = selectedPerk.title === perk.title

      return (
        <button
          className="mx-1 mb-6 flex items-center justify-start flex-wrap focus:shadow-none"
          style={{ WebkitTapHighlightColor: 'transparent' }}
          type="button"
          onClick={() => setSelectedPerk(perk)}
        >
          <img
            src={directusDownloadUrl(perk.img)}
            alt={perk.title}
            className={`${
              !isSelected ? 'filter grayscale' : 'transform scale-125'
            } w-full mx-auto transition duration-200`}
            style={{ maxWidth: '120px' }}
          />
          <h5 className="text-sm mt-2 text-center w-full">{perk.title}</h5>
        </button>
      )
    })}
  </div>
)

ListOfPerks.defaultProps = {
  className: '',
}

export default ListOfPerks
