import { gsap } from 'gsap/all'
import React, { FC, useRef } from 'react'
import Subtitle from 'src/components/ui/Subtitle'
import H2 from 'src/components/ui/title/H2'
import FourHeadedItemsGrid from 'src/components/ui/FourHeadedItemsGrid'
import {
  useSingleIconAnimation,
  useFourIconsInRowAnimations,
} from 'src/components/sections/four-icons-in-row/animations'
import styled from 'styled-components'
import tailwind from 'src/helpers/tailwind'

interface ISingleIcon {
  id: string
  image: {
    url: string
    alt: string
  }
  title: string
  content: string
}

export interface IFourIconsInRowProps {
  translationContent: {
    icons: ISingleIcon[]
    firstPartOfTitle: string
    secondPartOfTitle: string
    subtitle: string
    sectionName: string
  }
  smallIconTitles?: boolean
}

const FirstRowNoMargin = styled.div`
  &:first-child {
    margin-top: 0;
  }
  @media (min-width: ${tailwind.theme.screens.md}) {
    &:nth-child(3) {
      margin-top: 0;
    }
  }
  @media (min-width: ${tailwind.theme.screens.lg}) {
    &:nth-child(5),
    &:nth-child(7) {
      margin-top: 0;
    }
  }
`

const mappedIconsToComponents = (
  icons: ISingleIcon[],
  smallIconTitles: boolean,
  timeline: any
) =>
  icons.flatMap(({ id, image, title, content }) => {
    const iconRef = useRef<HTMLDivElement>(null)
    const iconContent = useRef<HTMLDivElement>(null)

    useSingleIconAnimation(timeline, iconRef, iconContent)

    return [
      <FirstRowNoMargin
        ref={iconRef}
        key={`d${id}`}
        className="opacity-0 flex flex-col md:flex-row justify-center items-start md:flex-wrap mt-16"
      >
        <div className="w-full">
          <img
            src={image.url}
            alt={image.alt}
            style={{ maxWidth: '144px' }}
            className="mx-auto"
          />
        </div>
        <div className="text-center w-full h-full flex justify-around items-center md:block">
          <h4
            className={`md:text-lg ${
              smallIconTitles ? 'lg:text-xl' : 'text-lg lg:text-2xl'
            } mt-2 font-palanquinDark whitespace-pre-line`}
          >
            {title}
          </h4>
        </div>
      </FirstRowNoMargin>,
      <p
        ref={iconContent}
        key={`p${id}`}
        className="opacity-0 text-center font-base text-xs lg:text-base md:text-left mt-4 whitespace-pre-line"
      >
        {content}
      </p>,
    ]
  })

const FourIconsInRow: FC<IFourIconsInRowProps> = ({
  translationContent: {
    icons,
    subtitle,
    firstPartOfTitle,
    secondPartOfTitle,
    sectionName,
  },
  smallIconTitles,
}) => {
  const timeline = useRef(gsap.timeline())

  const subtitleRef = useRef<HTMLDivElement>(null)
  const pageTitleRef = useRef<HTMLDivElement>(null)

  useFourIconsInRowAnimations(timeline, subtitleRef, pageTitleRef)

  return (
    <section data-name={sectionName} className="container py-10 md:py-20">
      <div>
        <Subtitle ref={subtitleRef} className="mb-2 md:mb-6 opacity-0 ">
          {subtitle}
        </Subtitle>
        <H2
          className="opacity-0 "
          ref={pageTitleRef}
          firstPart={firstPartOfTitle}
          secondPart={secondPartOfTitle}
        />
        <FourHeadedItemsGrid className="gap-x-12 mt-16 md:mt-12">
          {mappedIconsToComponents(icons, smallIconTitles, timeline)}
        </FourHeadedItemsGrid>
      </div>
    </section>
  )
}

FourIconsInRow.defaultProps = {
  smallIconTitles: false,
}

export default FourIconsInRow
