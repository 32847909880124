import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const announcementsAnimations = (
  timeline,
  subtitleRef: MutableRefObject<HTMLDivElement>
) => {
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline, subtitleRef.current)

    ScrollTrigger.sort()
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export const singleAnnouncementAnimation = (
  timeline,
  singleAnnouncementRef: MutableRefObject<HTMLDivElement>
) => {
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline, singleAnnouncementRef.current)

    ScrollTrigger.sort()
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
