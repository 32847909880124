import React, { FC } from 'react'
import { IPerk } from 'src/components/sections/benefits-and-perks'
import { directusDownloadUrl } from 'src/helpers/directus'
import tailwind from 'src/helpers/tailwind'
import styled from 'styled-components'

interface ISelectedPerkProps {
  className?: string
  perk: IPerk
}

const StyledParagrpah = styled.p`
  @media (min-width: ${tailwind.theme.screens.md}) {
    min-height: 120px;
  }
`

const SelectedPerk: FC<ISelectedPerkProps> = ({ className, perk }) => (
  <div className={`${className}`}>
    <div className="">
      <img
        src={directusDownloadUrl(perk.img)}
        alt={perk.title}
        className="w-48 md:w-full"
      />
      <h5 className="text-3xl mt-6 font-bold">{perk.title}</h5>
      <StyledParagrpah key={perk.title} className="mt-6">
        {perk.description}
      </StyledParagrpah>
    </div>
  </div>
)

SelectedPerk.defaultProps = {
  className: '',
}

export default SelectedPerk
